import React from "react";
import { Flex, Text, MaxWidth, Box } from "@sqymagma/elements"
import { Link } from "gatsby"
import { theme } from "../utils/themeGet"
import SEO from "../components/Seo";

import LoadableVisibility from "react-loadable-visibility/react-loadable";
const Loading = _ => <Box bg="ui07" minHeight="100vh"/>

// Loadable components
const OriginSection = LoadableVisibility({
  loader: () => import("../components/Context/OriginSection"),
  loading: Loading
});
const ZoonosisSection = LoadableVisibility({
  loader: () => import("../components/Context/ZoonosisSection"),
  loading: Loading
});
const DiseaseSection = LoadableVisibility({
  loader: () => import("../components/Context/DiseaseSection"),
  loading: Loading
});
const InfectionSection = LoadableVisibility({
  loader: () => import("../components/Context/InfectionSection"),
  loading: Loading
});
const SympthonsSection = LoadableVisibility({
  loader: () => import("../components/Context/SympthonsSection"),
  loading: Loading
});
const TransmissionSection = LoadableVisibility({
  loader: () => import("../components/Context/TransmissionSection"),
  loading: Loading
});
const ProcessSection = LoadableVisibility({
  loader: () => import("../components/Context/ProcessSection"),
  loading: Loading
});
const EuropeSection = LoadableVisibility({
  loader: () => import("../components/Context/EuropeSection"),
  loading: Loading
});
const LocalCasesSection = LoadableVisibility({
  loader: () => import("../components/Context/LocalCasesSection"),
  loading: Loading
});


const ContextPage = () => {
  return (
    <>
    <SEO
      title={'Contexto'}
      description={'Echamos la vista atrás para reflexionar sobre el origen de la pandemia'}
      image={'/images/open-graph-image-yellow.png'}
      favicon={'/images/svg/virus-yellow-ico.svg'}
    />
    <Box minHeight="100vh" bg="brand04">
        <div id="el-origen">
        <OriginSection/>
        </div>
    </Box>
    <Box minHeight="100vh" bg="brand04">
        <div id="una-zoonosis-emergente">
        <ZoonosisSection/>
        </div>
    </Box>  
    <Box minHeight="100vh" bg="brand04">
        <div id="la-enfermedad-covid-19">
        <DiseaseSection/>
        </div>
    </Box>
    <Box minHeight="100vh" bg="brand04">
        <div id="como-infecta-la-celula-humana">
        <InfectionSection/>
        </div>
    </Box>
    <Box minHeight="100vh" bg="brand04">
        <SympthonsSection/>
        <div id="sintomas">
        </div>
    </Box>
    <Box minHeight="100vh" bg="brand04">
        <div id="mecanismo-de-transmision">
        <TransmissionSection/>
        </div>
    </Box>
    <Box minHeight="100vh" bg="brand04">
        <div id="proceso-y-estados">
        <ProcessSection/>
        </div>
    </Box>
    <Box minHeight="100vh" bg="brand04">
        <div id="el-desembarco-en-europa">
        <EuropeSection/>
        </div>
    </Box>
    <Box minHeight="100vh" bg="brand04">
        <div id="de-casos-importados-a-transmision-local">
        <LocalCasesSection/>
        </div>
    </Box>

    <BottomBanner/>

    </>
  )
}

export default ContextPage

const BottomBanner = () => {
  return (
    <Link to="/cronologia/semana-00">
    <Flex width={1} bg="brand04" py="xl"
        css={`{
            transition: background-color ease-in .3s, color ease-in .4s;
            border-bottom: 1px solid rgba(0,0,0,.3);
            border-top: 1px solid rgba(0,0,0,.3);
            &:hover {
                background-color: ${theme("color.ui07")};
                h2, h4 {
                    color: ${theme("color.brand04")};
                }
            }
        }`}
    >
        <MaxWidth>
            <Flex alignItems="center" justifyItems="space-between" flexDirection={['column','row']}>

            <Flex width={[1, 3/4]} alignItems="center" mb={['s', 0]}>
                <Text as="h2" fontWeight="800" lineHeight="1.05" fontSize={["40px",null,null, null, null,"56px"]} color="ui07" mr="m">
                  Ir a la semana 0
                </Text>
                <AnimatedArrow/>
            </Flex>

            <Box width={[1, 1/4]}>
                <Text as="h4" textStyle="l" color="ui07" pl="xs">
                Revisita los primeros días de la pandemia en España
                </Text>
            </Box>

            </Flex>
        </MaxWidth>
    </Flex>
      </Link>
  )
}

const AnimatedArrow = _ => (
    <Flex width="xxl" mt="xs" display={['none', null, 'flex']}
    css={`{flex: auto}`}>
        <Box maxWidth="150px">
        <object type="image/svg+xml" data={'/images/anim/flecha_ani_00.svg'} aria-label={'flecha animada'} alt={'flecha animada'} />
        </Box>
    </Flex>
)